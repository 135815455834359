import { Address, OperatingDay, OperatingDayV2, Phone, } from '../../../shared/types'
import { LocationArea } from '../google-geocode/types'
import { GenericCoordinates, LocationWithMarker } from '../location.types'

export interface AARGenericData {
  type: string
  content: string
}

export interface AARData extends GenericCoordinates {
  id: number
  type: string
  name: string
  distanceTo: number
  address: Address
  phone: Phone
  serviceProviderCode?: string
  operatingDays?: OperatingDay[]
  serviceOffers?: string[]
  vehicles?: AARVehicle[]
  services?: AARGenericData[]
  ratingSummary?: RatingSummary
  evStationDetails?: EvStationDetails
  distance?: number | string
  priority?: boolean
  emailAddress?: string
  webAddress?: string
  isDiscountAvailable?: boolean
  club?: string
}

export interface FacilitiesDisplay {
  aarData: AARData[]
  fitAll: boolean
  markers: LocationWithMarker[]
  expandedResult: boolean
}

export interface AARVehicle {
  type?: string
  value: string
}

export interface RatingSummary {
  businessId?: string
  reviewCount?: number
  ratingAvg?: number
  recommendPct?: number
}

export interface AARRequestParams extends LocationArea {
  make: string
  vehicleSlug: string
  searchType: SearchType
}

export const COR_TYPE = 'COR'
export const RATING_SUMMARY_FIELD = 'ratingSummary'
export const MAKES_FIELD = 'makes'
export const SERVICES_FIELD = 'services'

export const CUSTOM_TOW_DISTANCE_LIMIT = 2
export const CUSTOM_TOW_LIMIT_NEARBY = 3
export const CUSTOM_TOW_MIN_RATING = 4

export interface EvStationDetailsApiModel extends Omit<EvStationDetails, 'connectorTypes'> {
  connectorTypes: string[]
}

export interface AarDataApiModel extends Omit<AARData, 'evStationDetails'> {
  evStationDetails: EvStationDetailsApiModel
}

export enum SearchType {
  BREAKDOWN_LOCATION,
  CUSTOM_LOCATION,
}

export enum TowDestinationType {
  DEALERSHIP = 'DEALERSHIP',
  EV_STATION = 'EV_STATION',
  AAR = 'AAR',
  COR = 'COR',
  DUA = 'DUA',
  ADR = 'ADR',
  SMC = 'SMC',
}

export interface Service {
  code?: string
  text?: string
}

export interface TowDestinationDetailsResponse {
  id: string
  name: string
  country: string
  zip?: string
  postalCode?: string
  street_address?: string
  streetAddress?: string
  city: string
  state: string
  station_phone?: string
  phones?: Phone[]
  phoneNumber?: string
  webAddress?: string
  network_web?: string
  email?: string
  facility_type?: string
}

export interface AarDetailsResponse extends TowDestinationDetailsResponse {
  isDiscountAvailable: boolean
  priority: boolean
  serviceProviderCode: string
  ratingSummary?: RatingSummary
  operatingDays?: OperatingDayV2[]
  makes?: string[]
  services?: Service[]
  club?: string
}

export interface EvStationDetailsResponse extends TowDestinationDetailsResponse {
  connector_types: ConnectorTypes[]
  pricing: string
  free_pricing_ind: boolean
  updated_at: string
  date_last_confirmed: string
  facility_type: string | null
  access_days_time: string | null
  level_1_count: number
  level_1_ind: boolean
  level_2_count: number
  level_2_ind: boolean
  dc_fast_count: number
  dc_fast_ind: boolean
  other_ports: string | null
  renewable_source: string | null
}

export interface TowDestinationResults<T> {
  towDestinationType: TowDestinationType
  distance: string
  coordinates: GenericCoordinates
  details: T
}

export interface TowDestinationResponse<T> {
  results?: TowDestinationResults<T>[] //TODO remove optional (?) after Jan 15th
  result?: TowDestinationResults<T>[] //TODO remove this after Jan 15th
}

export interface EvStationDetails {
  chargeLevel: ChargeLevel
  connectorTypes: ConnectorTypes[]
  lastUpdated?: Date
  dateLastConfirmed?: string
  stationNotes?: string
  otherPorts?: string
  renewableSource?: string
  pricing?: string
  accessDaysTime?: string
}

export interface ChargeLevel {
  dcFast: number
  level1: number
  level2: number
}

export enum ConnectorTypes {
  NEMA1450 = 'NEMA 14-50',
  NEMA515 = 'NEMA 5-15',
  NEMA520 = 'NEMA 5-20',
  J1772 = 'J1772',
  J1772COMBO = 'J1772COMBO',
  CCS = 'CCS',
  CHADEMO = 'CHADEMO',
  TESLA = 'TESLA',
  UNKNOWN = 'Unknown',
}
