import { Injectable } from '@angular/core'
import { Adapter } from '../types'
import {
  AARData,
  AarDataApiModel,
  AarDetailsResponse,
  EvStationDetails,
  EvStationDetailsApiModel,
  EvStationDetailsResponse,
  TowDestinationDetailsResponse,
  TowDestinationResults,
  TowDestinationType
} from '../../modules/location/aar/aar.types';
import { ConnectorTypeAdapter } from './ConnectorTypeAdapter';

@Injectable({
  providedIn: 'root',
})
export class AARDataAdapter implements Adapter<AARData> {

  constructor(
    private connectorTypeAdapter: ConnectorTypeAdapter,
  ) {
  }

  adapt(data: AarDataApiModel): AARData {
    return {
      ...data,
      evStationDetails: this.getEvStationDetails(data.evStationDetails)
    }
  }

  private getEvStationDetails(apiModel: EvStationDetailsApiModel): EvStationDetails {
    return apiModel
      ? {
        ...apiModel,
        connectorTypes: apiModel.connectorTypes?.map(ct => this.connectorTypeAdapter.adapt(ct)),
      }
      : null
  }

  adaptTowDestination<T>(
    data: TowDestinationResults<T>
  ): AARData {
    let aarData: AARData
    const { details } = data

    aarData = this.adaptGenericTowDetails(data as TowDestinationResults<TowDestinationDetailsResponse> )

    switch (data.towDestinationType) {
      case TowDestinationType.EV_STATION:
        aarData = { ...aarData, ...this.adaptEvStationDetails(details as EvStationDetailsResponse) }
        break

      case TowDestinationType.AAR:
      case TowDestinationType.COR:
      case TowDestinationType.DUA:
      case TowDestinationType.ADR:
      case TowDestinationType.SMC:
        aarData = { ...aarData, ...this.adaptAarDetails(details as AarDetailsResponse) }
        break
    }

    return aarData
  }

  adaptEvStationDetails(
    details: EvStationDetailsResponse
  ): Partial<AARData> {
    return {
      evStationDetails: {
        pricing: details.pricing,
        accessDaysTime: details.access_days_time,
        dateLastConfirmed: details.date_last_confirmed,
        lastUpdated: new Date(details.updated_at),
        connectorTypes: details.connector_types,
        otherPorts: details.other_ports,
        renewableSource: details.renewable_source,
        chargeLevel: {
          level1: details.level_1_count,
          level2: details.level_2_count,
          dcFast: details.dc_fast_count
        },
      }
    }
  }

  adaptAarDetails(
    details: AarDetailsResponse
  ): Partial<AARData> {
    return {
      isDiscountAvailable: details.isDiscountAvailable,
      vehicles: details.makes?.map(make => ({ value: make })),
      serviceOffers: details.services?.map(service => service.text),
      priority: details.priority,
      serviceProviderCode: details.serviceProviderCode,
      club: details.club,
      operatingDays: details.operatingDays?.map(operatingDay => ({
        day: operatingDay.day,
        close: operatingDay.shifts?.[0].close,
        open: operatingDay.shifts?.[0].open,
      })),
      ratingSummary: details.ratingSummary,
    }
  }

  adaptGenericTowDetails(
    data: TowDestinationResults<TowDestinationDetailsResponse>
  ): AARData {
    return {
      id: Number(data.details.id),
      type: data.towDestinationType.toString(),
      longitude: data.coordinates.longitude,
      latitude: data.coordinates.latitude,
      distanceTo: Number(data.distance),
      name: data.details.name,
      webAddress: data.details.webAddress ?? data.details.network_web,
      emailAddress: data.details.email ?? null,
      phone: data.details.phones?.[0] ?? { value: data.details.station_phone || data.details.phoneNumber },
      address: {
        addressLine: data.details.streetAddress ?? data.details.street_address,
        cityName: data.details.city,
        stateProv: {
          code: data.details.state,
        },
        postalCode: data.details.postalCode ?? data.details.zip,
        countryName: {
          value: data.details.country,
          code: data.details.country,
        },
        type: data.details.facility_type ?? 'PHYSICAL',
      },
    }
  }

}
