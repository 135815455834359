import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { GoogleCoordinates } from '../google-geocode/types'
import { GoogleLocationMarker, MapBoundsPadding, MapState } from '../location.types'
import { ABTestParams, MarkerDetails } from '../../ui/ui.types'
import { selectIsAbTestParam } from '../../ui/ui.selectors'
import { CustomMarkerInfo } from '../location.types'
import { DEFAULT_MIN_ZOOM } from '../location.constants'
import { selectGpsLocationDenied } from '../location.selectors'

@Component({
  selector: 'app-map-wrapper',
  templateUrl: './map-wrapper.component.html',
})
export class MapWrapperComponent {
  @ContentChild('topSlot') topSlot: any
  @ContentChild('bottomSlot') bottomSlot: any

  @Input() zoomLevel = DEFAULT_MIN_ZOOM
  @Input() showGpsLocation = false
  @Input() centerMarker: MarkerDetails
  @Input() customMarkers: CustomMarkerInfo[]
  @Input() fitMarkers = false
  @Input() fitMapBounds = true
  @Input() tabIndexPosition = 0
  @Input() static = false
  @Input() showActionButtons = false
  @Input() isLoading = false
  @Input() enablePOICLick = false
  @Input() mapBoundsPadding: MapBoundsPadding
  @Input() mapPadding: Observable<MapBoundsPadding>
  @Input() userLocation
  @Input() center: GoogleCoordinates
  @Input() mapCenter: GoogleLocationMarker
  @Input() displayZoomAction = true
  @Input() displayLocateMeAction = false

  @Output() mapDrag = new EventEmitter<MapState>()
  @Output() mapClick = new EventEmitter<GoogleCoordinates>()
  @Output() markerClick = new EventEmitter<CustomMarkerInfo>()
  @Output() boundsChange = new EventEmitter<any>()
  @Output() centerChanged = new EventEmitter<GoogleCoordinates>()
  @Output() mapLoaded = new EventEmitter<void>()
  @Output() mapInitialized = new EventEmitter<any>()
  @Output() userLocationClick: EventEmitter<PointerEvent> = new EventEmitter()

  isAwsMap$: Observable<boolean> = this.store$.pipe(
    select(selectIsAbTestParam, ABTestParams.AWS_LOCATION_MAP)
  )
  hasDeniedGpsAccess$: Observable<boolean> = this.store$.pipe(select(selectGpsLocationDenied))

  constructor(private store$: Store) {}

  handleMapDrag(event: MapState) {
    this.mapDrag.emit(event)
  }

  handleMapClick(coords: GoogleCoordinates) {
    this.mapClick.emit(coords)
  }

  handleUserLocationClick(event: PointerEvent) {
    this.userLocationClick.emit(event)
  }
}
