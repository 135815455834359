<aaa-modal
  role="dialog"
  i18n-aria-label
  [isOpen]="isOpen"
  [initialBreakpoint]="1"
  [breakpoints]="loading ? [1] : [0,1]"
  [backdropDismiss]="!loading"
  contentMode="fit"
  [handle]="false"
  (aaaModalDidDismiss)="dismissModal()"
  customClass="location-details-modal"
  [padding]="false"
  >
  <ion-header [mode]="ionicMode" class="ion-margin-top">
    <ion-toolbar [mode]="ionicMode">
      <ion-buttons slot="start">
        <ion-button (click)="handleClose()" class="back-button" aria-label="Go back" i18n-aria-label>
          <aaa-icon slot="start" color="dark" name="chevron-left"></aaa-icon>
        </ion-button>
      </ion-buttons>
      <div adjust-location>
        <aaa-text size="title3" color="dark" align="center" i18n>Location Details</aaa-text>
        <aaa-text size="caption" weight="regular" class="margin-bottom-8" color="dark" align="center" i18n>Optional</aaa-text>
      </div>
    </ion-toolbar>
  </ion-header>

  <div class="ion-margin ion-padding-bottom">
    <div class="selected-address">
      <app-location-address (click)="handleClose()" [address]="address" [isEditable]="true"></app-location-address>
    </div>
    <drr-location-highway-exit />

    <ion-list>
      <ion-item *ngFor="let item of optionTemplates">
        <drr-checkbox [(ngModel)]="item.checked" (change)="handleChange(item)" [disabled]="loading"><aaa-text color="dark">{{item.name}}</aaa-text></drr-checkbox>
      </ion-item>
    </ion-list>

    <aaa-button class="next-button" expand="block" (click)="next()" [loading]="loading" i18n>
      Next
      <aaa-icon name="arrow-right"></aaa-icon>
    </aaa-button>

  </div>
</aaa-modal>
