import { Component, EventEmitter, Input, Output } from '@angular/core'
import { BreakdownLocationDetails, BreakdownLocationDetailsUi } from '../../../../location/location.types'
import { BREAKDOWN_LOCATION_OPTIONS } from '../../../breakdown-location.constants'
import { LocationAddress } from '../../breakdown-location.types'
import { AdobeEventService } from '../../../../tagging/adobe/event-adobe.service';
import { AdobeEventTypes } from '../../../../tagging/tagging.types';
import events from '../../../../tagging/events'

@Component({
  selector: 'location-details-sheet-variation',
  templateUrl: './location-details-sheet-variation.component.html',
  styleUrls: ['./location-details-sheet-variation.component.scss'],
})
export class LocationDetailsSheetVariationComponent {
  private _isOpen = false

  @Input() details: BreakdownLocationDetails
  @Input() address: LocationAddress
  @Input() loading: boolean

  @Input() get isOpen(): boolean {
    return this._isOpen
  }
  set isOpen(value: boolean) {
    this._isOpen = value
    this.isOpenChange.emit(this._isOpen)
  }

  @Output() detailsChange: EventEmitter<BreakdownLocationDetailsUi> = new EventEmitter<BreakdownLocationDetailsUi>()
  @Output() adjustLocationClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() nextClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() clearClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() isOpenChange = new EventEmitter<boolean>()
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>()


  optionTemplates = [
    { name: BREAKDOWN_LOCATION_OPTIONS().AT_HOME_IN_DRIVEWAY, checked: false },
    { name: BREAKDOWN_LOCATION_OPTIONS().IN_PARKING_GARAGE, checked: false },
    { name: BREAKDOWN_LOCATION_OPTIONS().IN_PARKING_LOT, checked: false },
    { name: BREAKDOWN_LOCATION_OPTIONS().ON_SIDE_ROAD, checked: false },
    { name: BREAKDOWN_LOCATION_OPTIONS().ON_INTERSTATE_HIGHWAY, checked: false },
  ]

  ionicMode = 'ios'

  constructor(private adobeEventService: AdobeEventService) {
  }

  dismissModal() {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.location.LOCATION_DETAILS_DISMISS
    })
    this.handleClose()
  }

  handleClose() {
    if (!this.loading) {
      this.onClose.emit()
    }
  }

  handleChange(item) {
    this.optionTemplates = this.optionTemplates.map((option) => {
      if (option.name !== item.name) {
        option.checked = false
      }
      return option
    })

    this.detailsChange.emit({
      ...this.details,
      options: [{ name: item.name }],
      checked: item.checked,
    })
  }

  adjustLocation() {
    this.adjustLocationClick.emit()
    this.handleClose()
  }

  next() {
    this.nextClick.emit()
  }

  clear() {
    this.clearClick.emit({})
    this.isOpen = false
  }
}
