import { createAction, props } from '@ngrx/store'

import { ABTestParams, Dialog, ErrorDialog, MessageDialog, PageNavigationEvent, PromptDialog, Step } from './ui.types'
import { createAsyncConstants } from '../../shared/utils'
import { NavigationEvent, TransitionEvent } from '../../shared/types'
import { GoogleCoordinates } from '../location/google-geocode/types'
import { CaptchaVersion, Vehicle } from '../member/member.types'
import { PaceSetterSituation } from '../issue/issue.types'
import { DISTANCE_UNIT } from '../../shared/i18n/i18n.types'

export enum DIALOG_OPEN {
  MESSAGE = 'DIALOG_OPEN.MESSAGE',
  ERROR = 'DIALOG_OPEN.ERROR',
  PROMPT = 'DIALOG_OPEN.PROMPT',
}

export const DIALOG_CLOSE = 'DIALOG_CLOSE'
export const DIALOG_RESET = 'DIALOG_RESET'

export const PENDING_STEP = 'PENDING_STEP'
export const EDIT_STEP = 'EDIT_STEP'
export const ACTIVE_STEP = 'ACTIVE_STEP'
export const DONE_STEP = 'DONE_STEP'

export const SET_SPLASHSCREEN_STEP = 'SET_SPLASHSCREEN_STEP'
export const SET_IS_COUPON_APPLIED = 'SET_IS_COUPON_APPLIED'
export const HIDE_SPLASHSCREEN = 'HIDE_SPLASHSCREEN'
export const SHOW_SPLASHSCREEN = 'SHOW_SPLASHSCREEN'
export const ANIMATE_SPLASHSCREEN_HIDE = 'ANIMATE_SPLASHSCREEN_HIDE'
export const CANCEL_STEPS_AHEAD = 'CANCEL_STEPS_AHEAD'

export const SET_IS_CAPTCHA_REQUIRED = 'SET_IS_CAPTCHA_REQUIRED'
export const SET_CAPTCHA_VERSION = 'SET_CAPTCHA_VERSION'
export const SET_CAPTCHA_MODAL_OPEN = 'SET_CAPTCHA_MODAL_OPEN'
export const SET_REMOVE_CALL_AAA = 'SET_REMOVE_CALL_AAA'

export const TRANSITION = createAsyncConstants('TRANSITION_START')
export const REQUEST_ROUTER_NAVIGATE = 'REQUEST_ROUTER_NAVIGATE'
export const SET_USER_DEFAULT_COORDS = 'SET_USER_DEFAULT_COORDS'
export const USER_DEFAULT_COORDS_LOADED = 'USER_DEFAULT_COORDS_LOADED'
export const SET_USER_DEFAULT_ZIPCODE = 'SET_USER_DEFAULT_ZIPCODE'
export const RESET_SKIP_ISSUE = 'RESET_SKIP_ISSUE'

export const ADD_TOWING_STEP = 'ADD_TOWING_STEP'
export const REMOVE_VEHICLE_STEP = 'REMOVE_VEHICLE_STEP'
export const REMOVE_ISSUE_STEP = 'REMOVE_ISSUE_STEP'

export const SET_PROD_ENV = 'SET_PROD_ENV'

export const SET_SHOW_SERVICE_HISTORY = 'SET_SHOW_SERVICE_HISTORY'

export const SET_TELEMATICS = 'SET_TELEMATICS'
export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_QUERY_PARAMS_VEHICLE_DATA = 'SET_QUERY_PARAMS_VEHICLE_DATA'

export const SET_FIRST_TIME_ACCESS = 'SET_FIRST_TIME_ACCESS'
export const SET_CHANNEL = 'SET_CHANNEL'
export const SET_CURRENT_URL_AND_TITLE = 'SET_CURRENT_URL_AND_TITLE'
export const SET_PROBLEM_TOOLTIP = 'SET_PROBLEM_TOOLTIP'
export const SET_CTA_LOGIN_PAGE = 'SET_CTA_LOGIN_PAGE'
export const SET_CENTER_IMAGE_AND_COPY = 'SET_CENTER_IMAGE_AND_COPY'

export const SET_WATCH_RECAPTCHA_POSITION = 'SET_WATCH_RECAPTCHA_POSITION'

export const SET_MOCK_CAPTCHA = 'SET_MOCK_CAPTCHA'

export const SET_AB_TEST_PARAM = 'SET_AB_TEST_PARAM'
export const SET_FULL_MAP_BREAKDOWN_LOCATION ='SET_FULL_MAP_BREAKDOWN_LOCATION'
export const SET_DISTANCE_UNIT ='SET_DISTANCE_UNIT'

export const openMessageDialog = createAction(
  DIALOG_OPEN.MESSAGE,
  props<{ payload: MessageDialog }>()
)

export const openErrorDialog = createAction(
  DIALOG_OPEN.ERROR,
  props<{ payload: ErrorDialog }>()
)

export const openPromptDialog = createAction(
  DIALOG_OPEN.PROMPT,
  props<{ payload: PromptDialog }>()
)

export const closeDialog = createAction(
  DIALOG_CLOSE,
  props<{ payload: { type: Dialog['type'] } }>()
)

export const resetDialogs = createAction(DIALOG_RESET)

export const pendingStep = createAction(
  PENDING_STEP,
  props<{ payload: Step }>()
)

export const editStep = createAction(EDIT_STEP, props<{ payload: Step }>())

export const activeStep = createAction(ACTIVE_STEP, props<{ payload: Step }>())

export const doneStep = createAction(DONE_STEP, props<{ payload: Step }>())

export const showSplashscreen = createAction(SHOW_SPLASHSCREEN)

export const hideSplashscreen = createAction(HIDE_SPLASHSCREEN)

export const animateSplashscreenHide = createAction(ANIMATE_SPLASHSCREEN_HIDE)

export const cancelStepsAhead = createAction(CANCEL_STEPS_AHEAD)

export const setSplashscreenStep = createAction(
  SET_SPLASHSCREEN_STEP,
  props<{ payload: 0 | 1 | 2 | 3 }>()
)

export const setIsCouponApplied = createAction(
  SET_IS_COUPON_APPLIED,
  props<{ payload: boolean }>()
)

export const setCaptchaModalOpen = createAction(
  SET_CAPTCHA_MODAL_OPEN,
  props<{ payload: boolean }>()
)

export const setCaptchaVersion = createAction(
  SET_CAPTCHA_VERSION,
  props<{ payload: CaptchaVersion }>()
)

export const transitionStart = createAction(
  TRANSITION.REQUEST,
  props<{ payload: TransitionEvent }>()
)

export const notifyTransitionEnded = createAction(TRANSITION.SUCCESS)

export const requestRouterNavigate = createAction(
  REQUEST_ROUTER_NAVIGATE,
  props<{ payload: NavigationEvent }>()
)

export const setUserDefaultCoords = createAction(
  SET_USER_DEFAULT_COORDS,
  props<{ payload: GoogleCoordinates }>()
)

export const userDefaultCoordsLoaded = createAction(USER_DEFAULT_COORDS_LOADED)

export const resetSkipIssue = createAction(RESET_SKIP_ISSUE)

export const addTowingStep = createAction(
  ADD_TOWING_STEP,
  props<{ payload: boolean }>()
)

export const removeVehicleStep = createAction(REMOVE_VEHICLE_STEP)
export const removeIssueStep = createAction(REMOVE_ISSUE_STEP)

export const setProdEnv = createAction(SET_PROD_ENV)

export const setUserDefaultZipCode = createAction(
  SET_USER_DEFAULT_ZIPCODE,
  props<{ payload: string }>()
)

export const setShowServiceHistory = createAction(
  SET_SHOW_SERVICE_HISTORY,
  props<{ payload: boolean }>()
)

export const setTelematics = createAction(
  SET_TELEMATICS,
  props<{ payload: string }>()
)

export const setCountry = createAction(
  SET_COUNTRY,
  props<{ payload: string }>()
)

export const setQueryParamsVehicleData = createAction(
  SET_QUERY_PARAMS_VEHICLE_DATA,
  props<{ payload: Vehicle }>()
)

export const setFirstTimeAccess = createAction(
  SET_FIRST_TIME_ACCESS,
  props<{ payload: boolean }>()
)

export const setChannel = createAction(
  SET_CHANNEL,
  props<{ payload: string }>()
)

export const setCurrentUrlAndTitle = createAction(
  SET_CURRENT_URL_AND_TITLE,
  props<{ payload: PageNavigationEvent}>()
)

export const setProblemTooltip = createAction(
  SET_PROBLEM_TOOLTIP,
  props<{ payload: PaceSetterSituation }>()
)

export const setWatchRecaptchaPosition = createAction(
  SET_WATCH_RECAPTCHA_POSITION,
  props<{ payload: boolean}>()
)

export const setMockCaptcha = createAction(
  SET_MOCK_CAPTCHA,
  props<{ payload: boolean }>()
)

export const setABTestParam = createAction(
  SET_AB_TEST_PARAM,
  props<{ payload: [abTestParam: ABTestParams, value?: boolean] }>()
)

export const setFullMapBreakdownLocation = createAction(
  SET_FULL_MAP_BREAKDOWN_LOCATION,
  props<{ payload: boolean }>()
)

export const setDistanceUnit = createAction(
  SET_DISTANCE_UNIT,
  props<{ payload: DISTANCE_UNIT }>()
)
