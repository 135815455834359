import { Component, Input } from '@angular/core';
import { LocationAddress } from '../breakdown-location.types'
import { Size } from '@aaa-mobile/shared-components/lib/icon/icon.interfaces';
import { Color } from '@aaa-mobile/shared-components/interface';

@Component({
  selector: 'app-location-address',
  templateUrl: './location-address.component.html',
  styleUrls: ['./location-address.component.scss']
})
export class LocationAddressComponent {
  @Input()
  address: LocationAddress
  @Input()
  matcherHighlight: string
  @Input()
  iconSize: Size = 'medium'
  @Input()
  iconColor: Color = 'medium'
  @Input()
  isEditable = false

  getLeftIcon() {
    return this.address?.icon || 'map-pin'
  }

  getRightIcon() {
    return 'pencil'
  }
}
