<ng-template #topContent>
  <ng-content select="[topSlot]"></ng-content>
</ng-template>

<ng-template #bottomContent>
  <ng-content select="[bottomSlot]"></ng-content>
</ng-template>

<ng-template #defaultContent>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="isAwsMap$ | async; else googleMap">
  <app-aws-map
    [zoomLevel]="zoomLevel"
    [showGpsLocation]="showGpsLocation"
    [centerMarker]="centerMarker"
    [customMarkers]="customMarkers"
    [fitMarkers]="fitMarkers"
    [fitMapBounds]="fitMapBounds"
    [tabIndexPosition]="tabIndexPosition"
    [static]="static"
    [showActionButtons]="showActionButtons"
    [isLoading]="isLoading"
    [enablePOICLick]="enablePOICLick"
    [mapBoundsPadding]="mapBoundsPadding"
    [mapPadding]="mapPadding"
    [userLocation]="userLocation"
    [center]="center"
    [mapCenter]="mapCenter"
    [displayZoomAction]="displayZoomAction"
    [displayLocateMeAction]="displayLocateMeAction"
    [attributionControl]="true"
    (mapDrag)="handleMapDrag($event)"
    (mapClick)="handleMapClick($event)"
    (userLocationClick)="handleUserLocationClick($event)"
    [hasDeniedGpsAccess]="hasDeniedGpsAccess$ | async"
  >
    <div topSlot>
      <ng-container *ngTemplateOutlet="topContent"></ng-container>
    </div>
    <div bottomSlot>
      <ng-container *ngTemplateOutlet="bottomContent"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
  </app-aws-map>
</ng-container>

<ng-template #googleMap>
  <app-map
    [zoomLevel]="zoomLevel"
    [showGpsLocation]="showGpsLocation"
    [centerMarker]="centerMarker"
    [customMarkers]="customMarkers"
    [fitMarkers]="fitMarkers"
    [fitMapBounds]="fitMapBounds"
    [tabIndexPosition]="tabIndexPosition"
    [static]="static"
    [showActionButtons]="showActionButtons"
    [isLoading]="isLoading"
    [enablePOICLick]="enablePOICLick"
    [mapBoundsPadding]="mapBoundsPadding"
    [mapPadding]="mapPadding"
    [userLocation]="userLocation"
    [center]="center"
    [mapCenter]="mapCenter"
    [displayZoomAction]="displayZoomAction"
    [displayLocateMeAction]="displayLocateMeAction"
    (mapDrag)="handleMapDrag($event)"
    (mapClick)="handleMapClick($event)"
    (userLocationClick)="handleUserLocationClick($event)"
    [hasDeniedGpsAccess]="hasDeniedGpsAccess$ | async"
  >
    <div topSlot>
      <ng-container *ngTemplateOutlet="topContent"></ng-container>
    </div>
    <div bottomSlot>
      <ng-container *ngTemplateOutlet="bottomContent"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
  </app-map>
</ng-template>
