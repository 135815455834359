import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Vehicle, VehicleData } from '../../member/member.types'
import { combineLatest, Observable, of } from 'rxjs'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { selectMemberVehicles } from '../../member/member.selectors'
import { assignExistingVehicle, VEHICLE_DELETE, VEHICLE_LOAD, VEHICLE_UPDATE, } from '../../member/member.actions'
import { selectIsVehicleYearsStep, selectWorkingVehicle } from '../vehicle.selectors'
import { map } from 'rxjs/operators'
import { VehicleEditState } from '../vehicle.reducer'
import { TaggingService } from '../../tagging/tagging.service'
import { selectAllowAddVehicle, selectIsSecure } from '../../auth/auth.selectors'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { addPartialCallRequest } from '../../dashboard/calls.actions'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import events from '../../tagging/events'
import { EditService } from '../../../shared/services/edit.service'
import { selectIsPlateToVinBTestParam, selectIsPlateToVinCTestParam } from '../../ui/ui.selectors'
import { VehicleSections } from '../../ui/ui.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { setResetPlateToVinForm } from '../../ui/forms/forms.reducer'

@Component({
  selector: 'app-select-vehicle-step',
  templateUrl: './select-vehicle-step.component.html',
  styleUrls: ['./select-vehicle-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectVehicleStepComponent extends AbstractResponsiveComponent implements OnInit {
  isLoading$: Observable<Boolean> = combineLatest([
    this.store$.pipe(select(selectIsLoading(VEHICLE_LOAD.ACTION))),
    this.store$.pipe(select(selectIsLoading(VEHICLE_UPDATE.ACTION))),
    this.store$.pipe(select(selectIsLoading(VEHICLE_DELETE.ACTION))),
  ]).pipe(
    map((areActionsLoading: Boolean[]) => areActionsLoading.find(Boolean))
  )

  editYear$ = this.store$.pipe(
    select(selectIsVehicleYearsStep)
  )

  allowAddVehicle$: Observable<boolean> = this.store$.pipe(
    select(selectAllowAddVehicle)
  )

  workingVehicle$: Observable<VehicleEditState> = this.store$.pipe(
    select(selectWorkingVehicle)
  )

  memberVehicles$: Observable<VehicleData[]> = this.store$.pipe(
    select(selectMemberVehicles)
  )

  isEmptyVehicles$: Observable<boolean> = this.memberVehicles$.pipe(
    map((vehicles) => !vehicles || vehicles.length === 0)
  )

  isSecure$: Observable<boolean> = this.store$.pipe(select(selectIsSecure))

  isLicensePlateToVinB$ = this.store$.pipe(select(selectIsPlateToVinBTestParam))
  isLicensePlateToVinB
  isLicensePlateToVinC$ = of(true)
  isLicensePlateToVinC

  isAddVehicleOptionsOpen = false

  constructor(
    private store$: Store<AAAStore>,
    private tagging: TaggingService,
    public editService: EditService,
    private adobeEventService: AdobeEventService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.isLicensePlateToVinB$.subscribe((isLicensePlateToVinB) => {
        this.isLicensePlateToVinB = isLicensePlateToVinB
      }),
      this.isLicensePlateToVinC$.subscribe((isLicensePlateToVinC) => {
        this.isLicensePlateToVinC = isLicensePlateToVinC
      })
    )
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0)
  }

  addVehicle() {
    if (this.isLicensePlateToVinC) {
      this.isAddVehicleOptionsOpen = true
    } else if (this.isLicensePlateToVinB) {
      this.addVehicleByLicense()
    } else {
      this.addVehicleByMakeModel()
    }
  }

  addVehicleByLicense() {
    this.store$.dispatch(setResetPlateToVinForm())
    this.editService.editVehicleSection(false, '', VehicleSections.PLATE)
  }

  addVehicleByMakeModel() {
    this.editService.editVehicleSection(false, '', VehicleSections.YEARS)
  }

  assignVehicle(vehicle: Vehicle | VehicleData) {
    this.store$.dispatch(assignExistingVehicle({ payload: vehicle }))

    this.tagging.setClickEvent(
      events.vehicle.VEHICLE_PRELOADED_SELECT,
      events.vehicle.VEHICLE_SUSPICIOUS_PAGE_TYPE
    )
    this.tagging.setClickEvent(
      events.vehicle.VEHICLE_COMPLETE,
      events.vehicle.VEHICLE_SUSPICIOUS_PAGE_TYPE
    )

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: 'Saved Vehicle: ' + events.vehicle.VEHICLE_COMPLETE
    })

    this.store$.dispatch(addPartialCallRequest())
  }

  trackByMethod(index: number, el: any): number {
    return el.id
  }

  navigateBack() {
    this.editService.navigateBack();
  }
}
