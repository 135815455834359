<aaa-modal
  [isOpen]="isOpen"
  [padding]="false"
  [initialBreakpoint]="1"
  [backdropDismiss]="false"
  [handle]="false"
  (aaaModalDidPresent)="handleFocus()"
  customClass="location-search-modal"
  >

  <ion-header [mode]="ionicMode">
    <ion-toolbar [mode]="ionicMode">
      <ion-buttons slot="start">
        <ion-button (click)="handleClose()" aria-label="Go back" i18n-aria-label>
          <aaa-icon slot="start" color="dark" name="chevron-left"></aaa-icon>
        </ion-button>
      </ion-buttons>
      <ion-title i18n>Enter My Address</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="handleFocus()">
          <ion-icon name="search" color="dark"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

    <app-location-search
      i18n-placeholder
      placeholder="Search"
      [selectedAddress]="selectedAddress"
      [gpsAvailable]="gpsAvailable"
      [location]="location"
      [lastSearchLocation]="lastSearchLocation"
      [showUseCurrentLocation]="gpsAvailable"
      (locationSelected)="handleLocationSelected($event)"
      (useCurrentLocation)="handleUseCurrentLocation()"
      [useShadow]="false"
      (inputClear)="handleClear()"
    ></app-location-search>


</aaa-modal>
