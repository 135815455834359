<aaa-modal
  [isOpen]="isOpen"
  [showBackdrop]="false"
  [initialBreakpoint]="1"
  [breakpoints]="[1]"
  [backdropDismiss]="false"
  [backdropBreakpoint]="1"
  contentMode="fit"
  class="ion-no-margin ion-margin-vertical"
  [handle]="false"
  customClass="adjust-location-modal"
  [padding]="false"
  >
  <ion-header [mode]="ionicMode" class="ion-margin-top">
    <ion-toolbar [mode]="ionicMode">
      <ion-buttons slot="start">
        <ion-button (click)="handleClose()" class="back-button" aria-label="Go back" i18n-aria-label>
          <aaa-icon slot="start" color="dark" name="chevron-left"></aaa-icon>
        </ion-button>
      </ion-buttons>
      @if(adjustRequired) {
        <div adjust-location>
          <aaa-text size="title3" color="dark" align="center" i18n>Adjust your location</aaa-text>
          <aaa-text size="caption" color="tertiary" weight="semibold" align="center" i18n>Help us find you</aaa-text>
          <aaa-text size="caption" weight="regular" class="margin-bottom-8" color="dark" align="center" i18n>Drag the map to your precise breakdown location or enter a full address</aaa-text>
        </div>
      } @else {
        <div set-location>
          <aaa-text size="title3" color="dark" align="center" i18n>Set your location</aaa-text>
          <aaa-text size="caption" weight="regular" class="margin-bottom-8" color="dark" align="center" i18n>Drag map to move pin</aaa-text>
        </div>
      }
    </ion-toolbar>
  </ion-header>

  <div class="ion-no-padding card-container">
    <ion-searchbar
      id="location-searchbar"
      type="text"
      color="light"
      [mode]="ionicMode"
      [value]="selectedAddress"
      (click)="handleInputClick($event)"
      (ionClear)="handleClearAddress()"
      appCtaClickEvent="Enter Address"
      aria-label="search for a location"
      role="searchbox"
      i18n-aria-label
    >
    </ion-searchbar>
    <div class="ion-padding ion-margin-horizontal ion-margin-bottom">
      <drr-location-highway-exit />
      <aaa-button
        appCtaClickEvent="Confirm Location Card"
        class="confirm-button margin-top-8"
        expand="block"
        i18n
        (click)="handleConfirm()"
        aria-label="confirm location"
        i18n-aria-label
        [disabled]="adjustRequired"
        [loading]="loading"
      >
        Confirm Location
      </aaa-button>
    </div>
  </div>
</aaa-modal>
