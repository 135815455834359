 <ion-grid>
  <ion-row class="ion-align-items-center">
    <ion-col size="auto" class="padding-4">
      <aaa-icon [name]="getLeftIcon()" [size]="iconSize" [color]="iconColor"></aaa-icon>
    </ion-col>
    <ion-col class="padding-4">
      <ion-row class="address-line-1" *ngIf="address?.main_text">
        <ion-col>
          <ion-label [innerHTML]="address.main_text | matcherHighlight:matcherHighlight"/>
        </ion-col>
      </ion-row>
      <ion-row class="address-line-2" *ngIf="address?.secondary_text">
        <ion-col>
          <ion-label [innerHTML]="address.secondary_text | matcherHighlight:matcherHighlight"/>
        </ion-col>
      </ion-row>
    </ion-col>
    @if (isEditable) {
      <ion-col size="auto" class="padding-4">
        <aaa-icon [name]="getRightIcon()" [size]="iconSize" [color]="iconColor"></aaa-icon>
      </ion-col>
    }
  </ion-row>
</ion-grid>
