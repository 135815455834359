import { Component, EventEmitter, Input, Output } from '@angular/core'
import { LocationAddress } from '../../components/breakdown-location.types'
import { IonButton, IonButtons, IonHeader, IonToolbar, IonSearchbar } from "@ionic/angular/standalone";
import { ButtonComponent, IconComponent, ModalComponent, TextComponent } from '@aaa-mobile/shared-components';
import { DrrLocationHighwayExit } from '../../components/location-highway-exit/location-highway-exit.component';
import { SharedModule } from '../../../../shared'

@Component({
  selector: 'drr-adjust-location-sheet',
  templateUrl: './adjust-location-sheet.component.html',
  styleUrls: ['./adjust-location-sheet.component.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonSearchbar,
    TextComponent,
    SharedModule,
    ButtonComponent,
    IconComponent,
    DrrLocationHighwayExit,
    ModalComponent,
  ]
})
export class DrrAdjustLocationSheet {
  @Input() address: LocationAddress
  @Input() selectedAddress: string
  @Input() adjustRequired: boolean
  @Input() loading: boolean
  @Input() isOpen = true;

  @Output() onLocationConfirm = new EventEmitter<void>()
  @Output() onClearAddress = new EventEmitter<{}>()
  @Output() onClose = new EventEmitter<void>();
  @Output() onInputClick = new EventEmitter<void>();

  ionicMode = 'ios'

  handleClearAddress() {
    this.onClearAddress.emit({})
  }

  handleConfirm() {
    this.onLocationConfirm.emit()
  }

  handleClose() {
    this.onClose.emit();
  }

  handleInputClick(event: MouseEvent) {
    // prevent emitting event when the click is in the clear icon
    if ((event.target as HTMLElement).className.indexOf('searchbar-clear-icon') === -1) {
      this.onInputClick.emit();
    }
  }

}
