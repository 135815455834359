import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { AbstractComponent } from '../../../shared/abstract.component'
import {
  requestVehicleByPlate,
  resetVehicleByPlate,
  setVehicleByPlate,
  setVehicleByPlateColor,
  VEHICLE_BY_PLATE
} from '../vehicle.actions'
import { selectVehiclesByPlate } from '../vehicle.selectors'
import { EditService } from '../../../shared/services/edit.service'
import { Observable } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { PlateToVinFormState } from '../../ui/forms/forms.reducer'
import { selectPlateToVinForm } from '../../ui/forms/forms.selectors'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import events from '../../tagging/events'
import { selectMemberVehicles } from '../../member/member.selectors'
import { US_STATES } from '../../../shared/controls/input-us-state/input-us-state.constants'
import { VehicleSections } from '../../ui/ui.types'
import { selectIsLoading } from '../../ui/loading/loading.selectors'

export enum VehicleLoadStatus {
  NONE,
  FOUND_ONE,
  FOUND_MANY,
  NOT_FOUND,
}

@Component({
  selector: 'app-add-plate',
  templateUrl: './add-plate.component.html',
  styleUrls: ['./add-plate.component.scss'],
})
export class AddPlateComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild('vehicleColor') vehicleColor: any;

  @Output() back = new EventEmitter()

  form$: Observable<FormGroupState<PlateToVinFormState>> =
    this.store$.pipe(select(selectPlateToVinForm))
  form: FormGroupState<PlateToVinFormState>
  submitPending = false
  displayError = false
  displayAddVehicle = false

  memberVehicles$ = this.store$.pipe(select(selectMemberVehicles))

  isLoading$ = this.store$.pipe(select(selectIsLoading(VEHICLE_BY_PLATE.ACTION)))

  vehiclesByPlate$ = this.store$.select(selectVehiclesByPlate)
  vehicleLoadStatus = VehicleLoadStatus.NONE
  vehicle

  state: string
  errorState = false

  color: string
  errorColor = false

  licensePlate: string
  errorLicensePlate = false

  constructor(
    private store$: Store<AAAStore>,
    public editService: EditService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.form$.subscribe((form) => {
        this.form = form
        if (form.value.state) {
          this.state = US_STATES[form.value.state]
        }
        if (form.value.color) {
          this.color = form.value.color
        }
        if (form.value.licensePlate) {
          this.licensePlate = form.value.licensePlate.toUpperCase()
        }
      }),
      this.vehiclesByPlate$.subscribe((vehicles) => {
        if (vehicles) {
          this.vehicleLoadStatus = Boolean(vehicles?.length) ?
            (vehicles.length > 1 ? VehicleLoadStatus.FOUND_MANY : VehicleLoadStatus.FOUND_ONE) :
            VehicleLoadStatus.NOT_FOUND

          if (this.vehicleLoadStatus !== VehicleLoadStatus.NOT_FOUND) {
            this.vehicle = vehicles[0]
            if (this.submitPending) {
              this.addVehicle()
            }
          } else {
            this.vehicle = null
          }
        }
      }),
      this.isLoading$.subscribe((isLoading) => {
        if (!isLoading && !this.vehicle) {
          this.vehicleLoadStatus = VehicleLoadStatus.NOT_FOUND
          if (this.submitPending) {
            this.addVehicle()
          }
        }
      })
    )
  }

  ngOnDestroy() {
    super.ngOnDestroy()
  }

  setState(state: string) {
    this.state = US_STATES[state]
    this.errorState = false
    this.displayError = false
    this.vehicleColor?.ngSelect.focus()
  }

  setColor(color: string) {
    this.color = color
    this.errorColor = false
  }

  setVehiclePlate() {
    this.licensePlate = this.form.controls.licensePlate.value
    this.displayError = false
    this.errorLicensePlate = false
  }

  searchVehicle() {
    this.displayError = false
    this.submitPending = true
    this.vehicleLoadStatus = VehicleLoadStatus.NONE
    if (this.form.controls.licensePlate.isValid && this.form.controls.state.isValid) {
      this.errorLicensePlate = false
      const licensePlate = this.form.value.licensePlate.replace(/\s/g, '').toUpperCase()
      const state = US_STATES[this.form.value.state]
      this.store$.dispatch(requestVehicleByPlate({
        payload: {
          tag: licensePlate,
          state,
        }
      }))
    }
  }

  handleAddVehicle() {
    if (this.validateForm() && this.form.isValid) {
      const sameVehicleData =  this.vehicle?.tag === this.licensePlate && this.vehicle?.state === this.state
      if (this.vehicleLoadStatus !== VehicleLoadStatus.NONE || sameVehicleData) {
        this.searchVehicle()
      } else {
        this.addVehicle()
      }
    }
  }

  addVehicle() {
    this.submitPending = false
    this.displayAddVehicle = true
    if (this.vehicleLoadStatus === VehicleLoadStatus.NOT_FOUND) {
      this.displayError = true
    } else {
      this.store$.dispatch(setVehicleByPlateColor({ payload: { color: this.color } }))
      if (this.vehicleLoadStatus === VehicleLoadStatus.FOUND_MANY) {
        this.editService.editVehicleSection(false, '', VehicleSections.CONFIRM_OPTIONS)
      } else {
        this.store$.dispatch(setVehicleByPlate({ payload: {
          ...this.vehicle,
          color: this.color,
        } }))
        this.editService.editVehicleSection(false, '', VehicleSections.CONFIRM)
      }
    }
  }

  validateForm() {
    if (!this.form.controls.licensePlate.isValid) {
      this.errorLicensePlate = true
    }
    if (!this.state) {
      this.errorState = true
    }
    if (!this.color) {
      this.errorColor = true
    }
    return !this.errorLicensePlate && !this.errorState && !this.errorColor
  }

  handleDontKnowLicensePlate() {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.vehicle.PLATE_TO_VIN_DONT_KNOW_LICENSE
    })
    this.clearVehicleAndGoToYears()
  }

  clearVehicleAndGoToYears() {
    this.store$.dispatch(resetVehicleByPlate())
    this.editService.editVehicleSection(false, '', 'years')
  }

  handleBack() {
    this.back.emit()
  }
}
