import { BasicContactInfo, DestinationType } from '../../shared/types'
import { IssueType, PACE_SETTER_SITUATION_CODES, priorityCodeType as PriorityCodeType } from '../issue/issue.types'
import { BreakdownLocation, GenericCoordinates, TowLocation, } from '../location/location.types'
import { VehicleData } from '../member/member.types'
import { FuelType, VehicleType } from '../vehicle/vehicle.types'
import {
  ContractIdentityAgent,
  ContractIdentityOem,
  ContractIdentityProfileId,
  ContractIdentityVas
} from "../auth/eligibility.types";

export interface CallCancelParams {
  updateToken?: string
  callDate: string
  callId: string
  servicingClub: string
}

export interface CallCancelSuccess {
  callId: string
  callDate: string
}

// TODO manage inconsistent gateway types
export interface BreakdownLocationParams {
  latitude: number
  longitude: number
  zip: string
  state: string
  city: string
  street: string
  streetNumber: string
  country?: string
  location?: string
  landmark?: string
  driverDirections?: string
  streetAddress?: string
}

export interface VehicleCallData extends VehicleData {
  licensePlate?: string
  licensePlateState?: string
}

export interface VehicleCallDataAdapted {
  year: number | string
  make: string
  model: string
  color?: string
  vin?: string
  fuelType?: FuelType | string
  driveType?: "2WD" | "4WD" | "AWD"
  vehicleType?: VehicleType
  odometer?: number
  licensePlate?: string
  licensePlateState?: string
  trim?: string
}

export interface AAACallData {
  club: string
  priorityCode?: string
  cashRequired?: boolean
  callTaker?: string
  agentFirstName?: string
  agentLastName?: string
  comments?: string
  breakdownLocation: BreakdownLocationParams
  contactInfo: ContactInfo
  situationCodes: {
    pacesetterCode: string
    issueType: IssueType
    prioritySituation?: boolean
    priorityCodeType?: PriorityCodeType
  }
  towing: TowLocation
  vehicle: VehicleCallData
  numberOfPassengers?: number
  channel?: string
  eligibility?: CallEligibility
}

export interface AAACallDataAdapted {
  callType: CallType,
  contact: {
    firstName: string
    lastName: string
    contactType: "SYSTEM" | "CUSTOMER" | "TOWDEST" | "DRIVER" | "FACILITY"
    phones: {
      phoneNumber: string
      preferred: boolean
      phoneType: "CELLULAR" | "HOME" | "BUSINESS" | "FAX"
      smsOptIn: boolean
    }[]
  }
  vehicle: VehicleCallDataAdapted,
  service: {
    issueType: string
    flatbedRequested: boolean
    pacesetterCode?: string
    numberOfPassengers?: number
    priorityCode?: string
    priorityCodeType?: PriorityCodeType
    collision?: boolean
    redFlag?: boolean,
    paymentRequired?: boolean
  }
  eligibility?: CallEligibility
  breakdownLocation: {
    streetNumber: string
    streetName: string
    city: string
    state: string
    postalCode: string
    country: string
    latitude: number
    longitude: number
  }
  towDestination: {
    name: string
    emailAddress: string
    streetNumber: string
    streetName: string
    city: string
    latitude: number
    longitude: number
    state: string
    postalCode: string
    country: string
    type: DestinationType | string
    id: string
  }
  comments: {
    text: string
  }[]
}

export interface AAATracking {
  supported: boolean
  provider?: string
  url?: string
}

export interface AAACallStatus {
  callId: string
  callDate: string
  callToken: string
  updateToken: string
  trackToken: string
  callStatus: CALL_STATUS_CODES
  pta: string | Date
  servicingClub?: string
  pacesetterCode: PACE_SETTER_SITUATION_CODES
  serviceProvider?: string
  vehicle: VehicleData
  // TODO investigate this curveball on response data.
  breakdownLocation: BreakdownLocation | BreakdownLocationParams
  towDestination?: TowLocation
  numberOfPassengers?: number
  driverData: {
    eta: string
    etaUTC?: string | Date,
    code?: string
    gpsAccuracy?: number
    gpsTimeTime?: string
    name: string
    id: string | number
    facilityId: string
  } & GenericCoordinates
  tracking?: AAATracking
  _retries?: number // internal use
}

export interface ActiveCall {
  details: AAACallStatus
  timeline: Array<{
    active: boolean
    description: string
    title: string
  }>
  eta: Date
  arrivalTime: string
}

export interface CallResponse {
  callKey: string
  callId: string
  callDate: string
  servicingClub: string
  callStatus?: string
  callToken?: string
  updateToken?: string
  trackToken?: string
  pta?: string
  ptaInLocalTime?: string
}

export interface CallSubmission {
  data: AAACallData
  response: CallResponse & {
    callStatus: CALL_STATUS_CODES
  }
}

export interface ClubSupport {
  supported: boolean
}

export interface RequestFailure {
  ignoreFailure: boolean
}

export type ContactInfo = BasicContactInfo & {
  enableEmail: boolean
  enablePushNotifications: boolean
  enableSMS: boolean
  languagePreference: string
}

export enum CALL_STATUS_CODES {
  NEW = 'NEW', // Call submit success. Call status pending.
  RE = 'RE', // Call status response: Received
  IC = 'IC', // Call status response: In Cache (Received by the Gateway but not created yet, to be shown as Received)
  ER = 'ER', // Call status response: En Route
  AS = 'AS', // Call assigned to driver
  OL = 'OL', // Call status response: On Location
  OS = 'OS',
  UT = 'UT',
  TW = 'TW', // Call status response: Towing
  CA = 'CA', // Call status response: Cancelled
  XX = 'XX', // Call status response: Cancelled
  CL = 'CL', // Call status response: Completed
  CP = 'CP', // Call status response: RAP Close Pending same as Completed
  HD = 'HD', // Call status response: RAP HD same as Completed
}

export enum CallType {
  MEMBER = "MEMBER",
  RAP = "RAP",
}

export enum ContractType {
  OEM = "OEM",
  VAS = "VAS",
  AGENT = "AGENT",
  PROFILE_ID = "PROFILE_ID"
}

export interface CallEligibility {
  contractType: ContractType,
  contractIdentity?: ContractIdentityOem | ContractIdentityVas | ContractIdentityAgent | ContractIdentityProfileId,
  verificationId: string | number,
  receivingClubCode: string | number
}
