import { Inject, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectProdEnv } from '../ui/ui.selectors'
import { SHARED_CONFIG, SharedConfig } from '../../shared/shared.config'
import { setSharedConfig } from './config.actions'

export const PROD_BASE_URL = 'https://gatewayers.national.aaa.com'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config: SharedConfig

  constructor(
    @Inject(SHARED_CONFIG) private sharedConfig: SharedConfig,
    private store$: Store
  ) {
    this.baseUrl$.subscribe((isProd) => {
      this.config = {
        ...this.sharedConfig,
        baseURL: isProd ? PROD_BASE_URL : this.sharedConfig.baseURL,
      }
    })
  }

  baseUrl$: Observable<boolean> = this.store$.pipe(select(selectProdEnv))

  setConfig(config: Partial<SharedConfig>) {
    this.config = {
      ...this.config,
      ...config,
    }

    this.store$.dispatch(setSharedConfig({ payload: this.config }))
  }

  getConfig() {
    return this.config
  }
}
