import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { GoogleCoordinates } from '../google-geocode/types'
import { DEFAULT_LAT, DEFAULT_LNG, MAP_BOUNDS_PADDING } from '../location.constants'
import { GoogleLocationMarker, MapBoundsPadding, MapState, CustomMarkerInfo } from '../location.types'
import { catchError, debounceTime, filter, first, map, switchMap, timeout } from 'rxjs/operators'
import { ZoneEventEmitter } from '../../../zone-event-emitter'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { TaggingService } from '../../tagging/tagging.service'
import { CustomMarkerComponent } from '../../ui/custom-marker/custom-marker.component'
import { MarkerDetails, USER_LOCATION_MARKER } from '../../ui/ui.types'
import { AbstractComponent } from "../../../shared/abstract.component"
import * as maplibregl from 'maplibre-gl'
import { AttributionControl, LngLat, Map } from 'maplibre-gl'
import { LocationUtils } from '../location.utils'
import { ConfigService } from '../../config/config.service'
import { DEFAULT_MAX_MAP_ZOOM, DEFAULT_MIN_ZOOM } from '../location.constants'

@Component({
  selector: 'app-aws-map',
  templateUrl: './aws-map.component.html',
  styleUrls: ['./aws-map.component.scss']
})
export class AwsMapComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChildren('customMarkerComponents') customMarkerComponents: QueryList<CustomMarkerComponent>

  private _userLocation
  private shouldMove = true
  private mapDragged = false
  map: Map
  apiLoaded = false

  private coords: GoogleCoordinates = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
  }

  defaultUserLocationData: MarkerDetails = {
    ...USER_LOCATION_MARKER,
  }

  isDraggingMap$ = new BehaviorSubject(false)
  centralizeMap$ = new Subject<GoogleCoordinates>()
  centerChanged$ = new Subject()
  dragEnd$ = new Subject()
  dragStart$ = new Subject()

  _zoomLevel = DEFAULT_MIN_ZOOM
  @Input() get zoomLevel(){
    return this._zoomLevel
  }
  set zoomLevel(zoom) {
    console.log('zoomLevel', zoom)
    this._zoomLevel = zoom
    this.map?.setZoom(this._zoomLevel)
  }
  @Input() showGpsLocation = false
  @Input() centerMarker: MarkerDetails
  @Input() customMarkers: CustomMarkerInfo[]
  @Input() fitMarkers = false
  @Input() fitMapBounds = true
  @Input() tabIndexPosition = 0
  @Input() static = false
  @Input() showActionButtons = false
  @Input() isLoading = false
  @Input() enablePOICLick = false
  @Input() mapBoundsPadding: MapBoundsPadding
  @Input() mapPadding: Observable<MapBoundsPadding>
  @Input() attributionControl = true

  @Input() get userLocation() {
    return this._userLocation
  }
  set userLocation(value) {
    this._userLocation = value
  }

  @Input() get center(): GoogleCoordinates {
    return this.coords
  }
  set center(coords) {
    if (this.mapDragged) {
      this.centralizeMap$.next(coords)
    } else {
      this.onCentralizeMap(coords)
    }
  }

  @Input() displayZoomAction = true
  @Input() displayLocateMeAction = false
  @Input() hasLocationAccess = false
  @Input() hasDeniedGpsAccess = false
  @Input() isValidBreakdownLocation = false
  @Input() mapCenter: GoogleLocationMarker

  @Output() markerClicked = new EventEmitter()
  @Output() locationChanged = new EventEmitter()
  @Output() mapDrag: EventEmitter<MapState>
  @Output() mapClick: EventEmitter<GoogleCoordinates>
  @Output() adjustLocationClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() adjustPinClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() searchAreaClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() useCurrentLocation = new EventEmitter()
  @Output() onShopDetailsClose: EventEmitter<void> = new EventEmitter()
  @Output() userLocationClick: EventEmitter<PointerEvent> = new EventEmitter()

  private currentPadding: MapBoundsPadding = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }

  private readonly DEFAULT_ZOOM = 4
  private readonly DEFAULT_CENTER: [number, number] = [-95.7129, 37.0902] // Center of US

  private resizeObserver: ResizeObserver

  private readonly MIN_ZOOM = DEFAULT_MIN_ZOOM
  private readonly MAX_ZOOM = DEFAULT_MAX_MAP_ZOOM
  private readonly TIMEOUT_GET_COORDINATES = 2000
  private readonly TIME_BETWEEN_MAP_DRAG = 300

  private isSettingCoordinates = false
  private isMoving = false

  private readonly MAPLIBRE_CSS_URL = 'https://unpkg.com/maplibre-gl@3.x.x/dist/maplibre-gl.css'
  private loadedStyle: HTMLLinkElement | null = null

  constructor(
    private ngZone: NgZone,
    public taggingService: TaggingService,
    public adobeEventService: AdobeEventService,
    public locationUtils: LocationUtils,
    private configService: ConfigService
  ) {
    super()
    this.mapDrag = new ZoneEventEmitter<MapState>(ngZone)
    this.mapClick = new ZoneEventEmitter<GoogleCoordinates>(ngZone)
  }

  ngOnInit(): void {
    this.initializeMap()

    this.subscriptions.push(
      this.dragStart$.subscribe(() => this.isDraggingMap$.next(true)),
      this.centralizeMap$.pipe(
        switchMap(coords => this.isDraggingMap$.pipe(
          debounceTime(this.TIMEOUT_GET_COORDINATES),
          filter(isDragging => !isDragging),
          first(),
          map(() => coords)
        ))
      ).subscribe((coords) => this.onCentralizeMap(coords)),
      this.dragEnd$.pipe(
        switchMap(() => this.centerChanged$.pipe(
          debounceTime(this.TIME_BETWEEN_MAP_DRAG),
          first(),
          timeout(this.TIME_BETWEEN_MAP_DRAG),
          catchError(() => of('')),
        ))
      ).subscribe(() => this.onDragEnd())
    ),
    this.mapPadding?.subscribe(padding => {
      this.currentPadding = {
        ...this.currentPadding,
        ...padding
      }
      this.fitCenterMarker()
    })
  }

  private loadMapLibreCSS(): Promise<void> {
    if (this.loadedStyle) {
      return Promise.resolve()
    }

    return new Promise((resolve, reject) => {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = this.MAPLIBRE_CSS_URL

      link.onload = () => {
        this.loadedStyle = link
        resolve()
      }

      link.onerror = (err) => {
        reject(err)
      }

      document.head.appendChild(link)
    })
  }

  private async initializeMap() {
    try {
      await this.loadMapLibreCSS()

      if (!this.map) {
        this.map = new Map({
          container: 'map',
          style: `https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/drr-test/style-descriptor?key=${this.configService.getConfig().awsLocationApiKey}`,
          center: this.coords?.lng && this.coords?.lat ?
            [this.coords.lng, this.coords.lat] :
            this.DEFAULT_CENTER,
          zoom: this.zoomLevel || this.DEFAULT_ZOOM,
          minZoom: this.MIN_ZOOM,
          maxZoom: this.MAX_ZOOM,
          attributionControl: false,
        })

        if (this.attributionControl) {
          this.map.addControl(
            new AttributionControl({
              compact: true
            }),
            'bottom-right'
          )
        }

        // Set up resize observer
        this.resizeObserver = new ResizeObserver(() => {
          this.map?.resize()
        })
        this.resizeObserver.observe(document.getElementById('map'))

        this.map.once('load', () => {
          this.ngZone.run(() => {
            this.apiLoaded = true
            this.setupMapEventListeners()
            this.refreshCustomMarkers()
            this.map.resize() // Force resize after load
          })
        })
      }
    } catch (error) {
      console.error('Failed to load MapLibre CSS:', error)
    }
  }

  private setupMapEventListeners() {
    this.map.on('movestart', () => this.isMoving = true)

    this.map.on('moveend', (e: any) => {
      if (this.isSettingCoordinates || this.isMoving) {
        this.isMoving = false
        return
      }
      const center = e.target.getCenter()
      this.centerChanged$.next(center)
    })

    this.map.on('dragstart', () => this.dragStart$.next())
    this.map.on('dragend', () => this.dragEnd$.next())
    this.map.on('click', (e) => {
      if (!this.enablePOICLick) {
        e.preventDefault()
      }
      const { lng, lat } = e.lngLat
      this.mapClick.emit({ lng, lat })
    })
  }

  refreshCustomMarkers() {
    if (this.map && this.mapCenter && !this.isLoading && this.customMarkers) {
      this.customMarkerComponents?.forEach((customElements) => customElements.refreshMarker())
      this.handleMapFocus(this.mapCenter)
      this.refreshFitMarkers()
    }
  }

  refreshFitMarkers() {
    if (this.fitMarkers && this.customMarkers?.length) {
      const bounds = new maplibregl.LngLatBounds()

      this.customMarkers.forEach((marker) => {
        if (!isNaN(marker?.location.lat) && !isNaN(marker?.location.lng)) {
          bounds.extend([marker.location.lng, marker.location.lat])
        }
      })

      const mapBoundsPadding = {
        ...MAP_BOUNDS_PADDING,
        ...this.mapBoundsPadding
      }

      this.map.fitBounds(bounds, {
        padding: mapBoundsPadding
      })
    }
  }

  ngOnChanges(changes) {
    if (changes.customMarkers) {
      this.refreshCustomMarkers()
    }
  }

  zoomIn(event) {
    event.preventDefault()
    this.zoomLevel = this.map.getZoom() + 1
  }

  zoomOut(event) {
    event.preventDefault()
    this.zoomLevel = this.map.getZoom() - 1
  }

  onDragEnd = () => {
    this.isDraggingMap$.next(false)
    if (this.mapDrag) {
      this.handleDragEndEvent()
    }
  }

  onCentralizeMap = (coords: GoogleCoordinates) => {
    if (!coords || !this.map) return
    this.coords = (coords?.lat && coords?.lng) ? coords : this.coords

    this.isSettingCoordinates = true
    this.map.setCenter(new LngLat(this.coords.lng, this.coords.lat))
    this.isSettingCoordinates = false
  }

  handleCustomMarkerClick(customMarker: CustomMarkerInfo) {
    if (!customMarker.click) {
      return
    }
    customMarker.click()
    let tabIndex = customMarker.tabIndex
    this.customMarkers
      .filter(marker => marker.markerDetails.active)
      .forEach(marker => {
        marker.markerDetails.active = false
        tabIndex = marker.tabIndex > tabIndex ? marker.tabIndex : tabIndex
      })
    customMarker.markerDetails.active = true
    customMarker.tabIndex = tabIndex + 1
  }

  handleMapFocus(mapCenter) {
    if (mapCenter?.lat && mapCenter?.lng && this.map) {
      this.map.panTo([mapCenter.lng, mapCenter.lat])
    }
  }

  handleDragEndEvent() {
    this.mapDragged = true
    setTimeout(() => {
      const center = this.map?.getCenter()
      if (!this.shouldMove || !center) {
        return
      }

      const lat = center.lat
      const lng = center.lng

      if (lat === 0 || lng === 0) {
        return
      }

      const mapState = this.locationUtils.convertToAwsMapState(this.map)
      if (mapState) {
        this.mapDrag.emit(mapState)
      }
    }, this.TIME_BETWEEN_MAP_DRAG)
  }

  locateUser(event: PointerEvent) {
    event.preventDefault()
    this.userLocationClick.emit(event)
  }

  fitCenterMarker() {
    if (!this.map || !this.centerMarker) {
      return
    }

    const center = new LngLat(this.center.lng, this.center.lat)

    const bounds = new maplibregl.LngLatBounds()
    bounds.extend(center)

    // Apply padding and fit bounds
    this.map.fitBounds(bounds, {
      padding: {
        top: this.currentPadding.top || 0,
        bottom: this.currentPadding.bottom || 0,
        left: this.currentPadding.left || 0,
        right: this.currentPadding.right || 0
      }
    })

    // Calculate the offset percentage for the center marker
    const totalHeight = window.innerHeight
    const bottomPadding = this.currentPadding.bottom || 0
    const offsetPercentage = (bottomPadding / totalHeight) * 53

    // Update the center marker position
    const centerMarkerElement = document.querySelector('.center-marker') as HTMLElement
    if (centerMarkerElement) {
      centerMarkerElement.style.top = `calc(50% - ${offsetPercentage}%)`
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.resizeObserver?.disconnect()
    if (this.map) {
      this.map.remove()
    }
    // Remove the CSS when component is destroyed
    if (this.loadedStyle && document.head.contains(this.loadedStyle)) {
      document.head.removeChild(this.loadedStyle)
      this.loadedStyle = null
    }
  }
}
