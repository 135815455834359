import { Component, ElementRef, OnInit } from '@angular/core'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectBreakdownCoordinates, selectBreakdownLocationCoordinates } from '../../location/location.selectors';
import {
  AAALocation,
  AAR_FILTER_TYPES,
  AAR_SORTING_ORDER,
  AARSorting,
  GenericCoordinates,
  GoogleLocationMarker,
  LastSearchLocation,
  MapBoundsPadding,
  MapState,
  CustomMarkerInfo,
} from '../../location/location.types'
import {
  selectAarPreview,
  selectAarPreviewLocation,
  selectDestinationType,
  selectFacility,
  selectIsLoadingSearchArea,
  selectLastTowSearchArea,
  selectMapBoundsPadding,
  selectShopPreferences,
  selectSortedFacilitiesDisplay,
  selectSuggestedShop,
  selectTowLocationSearch
} from '../../location/aar/aar.selectors'
import { MarkerTypes, MessageDialogTypes, Option, StepTypes, SubmitSections, TowSections } from '../../ui/ui.types';
import {
  AAR_LOAD,
  requestMinimumAars,
  requestSearchArea,
  resetAar,
  setAarPreview,
  setHasRatingSummary,
  setSortingType,
  showAllAARs,
  showOpenAARsOnly
} from '../../location/aar/aar.actions'
import { AARData, FacilitiesDisplay } from '../../location/aar/aar.types';
import { DestinationType } from '../../../shared/types';
import {
  aarAppendLocationMarker,
  convertToLocationMarker,
  isAddressComplete,
  LocationUtils
} from '../../location/location.utils'
import { cancelStepsAhead, openMessageDialog } from '../../ui/ui.actions'
import {
  confirmTowSelection,
  requestAddressLookup,
  resetTowDestination,
  setAARAddress,
  setLastTowToSearchLocation,
  setPassengers
} from '../../location/tow-location/tow-location.actions'
import events from '../../tagging/events'
import { AdobeEventTypes } from '../../tagging/tagging.types';
import { addPartialCallRequest } from '../../dashboard/calls.actions';
import {
  selectAllowCustomTowDestination,
  selectIsMapStep,
  selectIsTowSelectionConfirmed,
  selectLastTowToSearchLocation,
  selectTowingStep,
  selectTowLocationMarker,
  selectTowLocationPreviewAddress,
  selectTowLocationPreviewMarker
} from '../../location/tow-location/tow-location.selectors';
import { getMarkerType } from '../../location/tow-location/tow-location.utils';
import { AdobeEventService } from "../../tagging/adobe/event-adobe.service";
import { Router } from "@angular/router";
import { EditService } from "../../../shared/services/edit.service";
import { Title } from "@angular/platform-browser";
import { selectIsLoading } from "../../ui/loading/loading.selectors";
import { isDeepCopy } from "../../../shared/utils/isDeepCopy";
import { AAAStore } from "../../../store/root-reducer";
import { RapService } from "../../rap/rap.service";
import { requestRouteDistance } from "../../route-distance/route-distance.actions";
import { TaggingService } from "../../tagging/tagging.service";
import { buildTitle } from "../../../shared/utils/title";
import { isIOS } from "../../../shared/utils/browser-detect";
import { AbstractComponent } from "../../../shared/abstract.component";

const TITLE_TOW_LOCATION = () => $localize`Tow Location`
const TITLE_PASSENGERS = () => $localize`Passengers`
const PASSENGER_TITLE_TRUCK_LIMIT = () => $localize`Truck capacity limited`
const PASSENGER_CONTENT_TRUCK_LIMIT = () => $localize`Trucks only have 2 seats available. In order to safely transport all of your passengers, please find other means of transportation.`

@Component({
  selector: 'app-tow-location-step',
  templateUrl: './tow-location-step.component.html',
  styleUrls: ['./tow-location-step.component.scss'],
})
export class TowLocationStepComponent extends AbstractComponent implements OnInit {

  step$ = this.store$.pipe(select(selectTowingStep))

  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(AAR_LOAD.ACTION))
  )

  showMapModal: Boolean = false
  suggestedShop: AARData
  suggestedShop$: Observable<AARData> = this.store$.pipe(
    select(selectSuggestedShop)
  )

  breakdownCoordinates
  breakdownCoordinates$: Observable<GenericCoordinates> = this.store$.pipe(
    select(selectBreakdownLocationCoordinates)
  )
  towLocationSearch$: Observable<MapState> = this.store$.pipe(
    select(selectTowLocationSearch)
  )
  towLocationSearch = null
  destinationShop = null
  isSeeFacilitiesClicked$ = new BehaviorSubject<boolean>(false)

  facilitiesDisplay$: Observable<FacilitiesDisplay> = this.store$.pipe(
    select(selectSortedFacilitiesDisplay)
  )

  aarPreview$: Observable<number | null> = this.store$.pipe(
    select(selectAarPreview)
  )
  towLocationMarkers$: Observable<
    [GoogleLocationMarker, GoogleLocationMarker, any]
  > = combineLatest([
      this.store$.pipe(select(selectTowLocationMarker)),
      this.store$.pipe(select(selectTowLocationPreviewMarker)),
      this.store$.pipe(select(selectFacility)),
    ])
  allowCustomTowDestination$: Observable<boolean> = this.store$.pipe(
    select(selectAllowCustomTowDestination)
  )
  destinationType$: Observable<DestinationType> = this.store$.pipe(
    select(selectDestinationType)
  )
  destinationType: DestinationType
  isEV = false

  isAddressValid = false
  towLocationValue = null
  get towLocation(): GoogleLocationMarker {
    return this.towLocationValue
  }

  isRapUser = this.rapService.isRapUser()
  allowCustomTowDestination: boolean

  lastSearchLocation$: Observable<LastSearchLocation> = this.store$.pipe(
    select(selectLastTowToSearchLocation)
  )

  selectedShop: AARData

  set towLocation(towLocation: GoogleLocationMarker) {
    if (!isDeepCopy(towLocation, this.towLocationValue)) {
      this.towLocationValue = towLocation || null
      this.isAddressValid =
        towLocation &&
        towLocation.hasOwnProperty('lat') &&
        towLocation.hasOwnProperty('lng')
    }
    this.sendPageLoadEvent()
  }

  towLocationPreviewAddress$: Observable<AAALocation> = this.store$.pipe(
    select(selectTowLocationPreviewAddress)
  )

  aarSelector = null
  shopPreviewId: number

  constructor(
    protected store$: Store<AAAStore>,
    protected locationUtils: LocationUtils,
    protected tagging: TaggingService,
    protected adobeEventService: AdobeEventService,
    protected router: Router,
    protected editService: EditService,
    private rapService: RapService,
    private titleService: Title,
    private elementRef: ElementRef
  ) {
    super()
  }

  lastSearchLocation
  lastSearchLocationArea$: Observable<GenericCoordinates> = this.store$.pipe(
    select(selectLastTowSearchArea)
  )

  ngOnInit() {
    isIOS() && this.setAvailableHeight()
    this.subscriptions.push(
      this.breakdownCoordinates$.subscribe((coordinates) => {
        this.breakdownCoordinates = coordinates
      }),
      this.lastSearchLocationArea$.subscribe((lastSearchLocation) => {
        this.lastSearchLocation = lastSearchLocation
      }),
      this.towLocationMarkers$.subscribe(([towLocation, preview, aarSelector]) => {
        this.towLocation = preview || towLocation || null
        this.aarSelector = aarSelector
        if (this.towLocation?.aarId) {
          this.selectedShop = aarSelector(this.towLocation.aarId)
          const marker = convertToLocationMarker(this.selectedShop)
          if (marker) {
            this.destinationShop = {
              markers: [marker],
            }
          }
        } else {
          this.selectedShop = null
        }
      }),
      this.allowCustomTowDestination$.subscribe((allowCustomTowDestination) => this.allowCustomTowDestination = allowCustomTowDestination),
      this.facilitiesDisplay$.subscribe((facilityDisplay) => {
        const hasRatingSummary = Boolean(facilityDisplay.aarData.find((data) => Boolean(data?.ratingSummary)))
        this.store$.dispatch(setHasRatingSummary({ payload: hasRatingSummary }))
      }),
      this.towLocationSearch$.subscribe((towLocationSearch) =>
        this.towLocationSearch = towLocationSearch
      ),
      this.aarPreview$.subscribe((aarPreview) => {
        this.shopPreviewId = aarPreview
      }),
      this.suggestedShop$.subscribe(aar => this.suggestedShop = aar),
      this.destinationType$.subscribe(destinationType => {
        this.destinationType = destinationType
        this.isEV = destinationType === DestinationType.EV_STATION
      }),
      this.towLocationPreviewAddress$.subscribe((preview) => {
        const isTowAddressIncomplete = preview?.address && !isAddressComplete(preview)
        if (isTowAddressIncomplete) {
          this.isSeeFacilitiesClicked$.next(true)
        }
      }),
      this.step$.subscribe((step) => {
        if (step === TowSections.PASSENGERS) {
          this.titleService.setTitle(buildTitle(TITLE_PASSENGERS(), this.rapService.isRapUser()))
          this.tagging.setPageEvent(
            events.towTo.PASSENGERS_PAGE_PROMPT,
            events.towTo.PASSENGERS_PAGE_TYPE
          )
        } else {
          this.titleService.setTitle(buildTitle(TITLE_TOW_LOCATION(), this.rapService.isRapUser()))
          this.tagging.setPageEvent(
            events.towTo.DESTINATION_PAGE_PROMPT,
            events.towTo.DESTINATION_PAGE_TYPE
          )
        }
      })
    )
  }

  setAvailableHeight = () => {
    const hostElement = this.elementRef.nativeElement as HTMLElement;
    const availableSpace = document.documentElement.offsetHeight - document.documentElement.clientHeight;
    hostElement.style.setProperty('--component-height', `calc(100% - ${availableSpace}px)`);
  }

  handleAddressSelected(address) {
    if (address?.description) {
      this.store$.dispatch(
        requestAddressLookup({
          payload: {
            address: address.description,
            ...(address.landmark ? { landmark: address.landmark } : {})
          } })
      )
      this.store$.dispatch(setLastTowToSearchLocation({ payload: address }))
      this.store$.dispatch(resetAar())
    } else {
      this.store$.dispatch(resetTowDestination())
      this.isSeeFacilitiesClicked$.next(false)
      this.store$.dispatch(cancelStepsAhead())
      this.store$.dispatch(requestSearchArea({
        payload: {
          center: this.breakdownCoordinates
        }
      }))
    }
  }

  lastPageName
  sendPageLoadEvent(force?: boolean) {
    let pageName = this.isAddressValid ? events.towTo.PAGE_NAME_CONFIRM_TOW_LOCATION : events.towTo.PAGE_NAME_TOW_TO_LIST
    if (this.showMapModal) {
      pageName = events.towTo.PAGE_NAME_TOW_TO_MAP
    }
    if(force || pageName !== this.lastPageName) {
      this.tagging.setPageLoadEvent({ pageType: events.towTo.PAGE_TYPE, pageName })
    }
    this.lastPageName = pageName
  }

  center$ = this.store$.pipe(select(selectBreakdownCoordinates))
  totalDistance = 0
  totalMarkers = 0
  mapLocation: MapState
  displaySearchArea = false
  aarDataSelected: AARData
  isModalVisible = false

  markers$: Observable<CustomMarkerInfo[]> = combineLatest([
    this.facilitiesDisplay$,
    this.breakdownCoordinates$,
    this.destinationType$,
    this.aarPreview$,
    this.store$.select(selectIsTowSelectionConfirmed),
  ]).pipe(
    map((
      [facilitiesDisplay, breakdownCoordinates, destinationType, aarPreview, towSelectionConfirmed]:
      [FacilitiesDisplay, GenericCoordinates, DestinationType, number, boolean]
    ) => {
      if (!facilitiesDisplay?.markers) {
        return []
      }
      this.totalMarkers = facilitiesDisplay.markers.length
      this.totalDistance = facilitiesDisplay.markers
        .map((shop) => this.locationUtils.haversineMiles(this.lastSearchLocation, shop.marker))
        .reduce((a, b) => a + b, 0)
      const markers: CustomMarkerInfo[] = facilitiesDisplay.markers.map(marker => ({
        location: {
          lat: marker.marker.lat,
          lng: marker.marker.lng,
        },
        markerDetails: {
          type: getMarkerType(marker.location, destinationType),
          active: this.shopPreviewId === marker.location.id,
        },
        tabIndex: this.shopPreviewId === marker.location.id ? 100 : 0,
        id: marker.marker.aarId.toString(),
        click: () => {
          this.selectDestination(marker.marker.aarId)
        },
      }))
      markers.push({
        location: {
          lat: Number(breakdownCoordinates.latitude),
          lng: Number(breakdownCoordinates.longitude),
        },
        markerDetails: {
          type: MarkerTypes.CAR,
        },
        tabIndex: 99,
        id: 'breakdown-pin',
      })
      if (this.towLocation && !this.towLocation.aarId) {
        markers.push({
          location: this.towLocation,
          markerDetails: {
            type: MarkerTypes.CUSTOM,
            active: this.isCustomTowDestination(),
          },
          tabIndex: 100,
          id: 'custom-pin',
          click: () => {
            this.store$.dispatch(setAarPreview({ payload: { id: null } }))
          },
        })
      }
      if (towSelectionConfirmed) {
        return markers.filter(marker => marker.markerDetails.type === MarkerTypes.CAR || marker.markerDetails.active)
      }
      return markers
    })
  )

  isLoadingSearchArea$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoadingSearchArea)
  )

  aarPreviewLocation$: Observable<AARData | null> = this.store$.pipe(
    select(selectAarPreviewLocation)
  )

  mapBoundsPadding$: Observable<MapBoundsPadding> = this.store$.pipe(
    select(selectMapBoundsPadding)
  )

  shopPreferences$ = this.store$.pipe(
    select(selectShopPreferences)
  )

  isMapStep$: Observable<boolean> = this.store$.pipe(
    select(selectIsMapStep),
    /*
     * This call makes Safari show the searchbar to prevent issues with ionic modal sheet on map page
     * Smooth scrolling behavior seems to not work
     * This can be moved to a global effect after the redesign if it's needed in other places
     */
    tap((isMapStep) => isMapStep && window.scrollTo(0, 0))
  )

  isListStep$: Observable<boolean> = this.isMapStep$.pipe(map((isMapStep) => !isMapStep))

  showMap(show: boolean) {
    const section = show ? TowSections.TOW_MAP : undefined

    this.showMapModal = show
    this.sendPageLoadEvent()

    this.editService.doEdit(StepTypes.TOWING, section, false)
  }

  isCustomTowDestination() {
    return this.shopPreviewId ? !this.shopPreviewId : !!this.towLocation
  }

  handleMapDrag(mapState: MapState) {
    if (this.isCustomTowDestination()) {
      this.displaySearchArea = false
      return
    }
    this.mapLocation = mapState
    if (!this.totalMarkers) {
      this.displaySearchArea = true
    } else {
      const distance = this.locationUtils.haversineMiles(this.lastSearchLocation, this.mapLocation.center)
      const avgDistance = this.totalDistance / this.totalMarkers
      this.displaySearchArea = distance > avgDistance
    }
  }

  selectDestination(id: number) {
    this.store$.dispatch(setAarPreview({ payload: { id } }))
  }

  handleSearchArea() {
    this.store$.dispatch(requestSearchArea({
      payload: this.mapLocation
    }))
    this.displaySearchArea = false
  }

  handleDestinationCardClick(aarData: AARData, suggested = false) {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: suggested ? events.towTo.DESTINATION_SUGGESTED_SHOP_CLICK : events.towTo.DESTINATION_CARD_SELECT,
    }, null, {
      location_name: aarData.name,
      location_code: aarData.id
    })
    this.showMap(true)
    this.selectDestination(aarData.id)
    this.store$.dispatch(confirmTowSelection({
      payload: false
    }))
  }

  handleCustomAddressSelected(address) {
    this.handleAddressSelected(address)
    this.showMap(true)
  }

  handleClearSelectedShop() {
    this.store$.dispatch(resetTowDestination())
    this.store$.dispatch(confirmTowSelection({
      payload: false
    }))
    this.store$.dispatch(setAarPreview({ payload: { id: null } }))
    this.store$.dispatch(cancelStepsAhead())
  }

  handleNext() {
    this.store$.dispatch(confirmTowSelection({
      payload: true
    }))

    if (
      this.isAddressValid &&
      this.breakdownCoordinates &&
      this.breakdownCoordinates.latitude &&
      this.breakdownCoordinates.longitude
    ) {
      this.store$.dispatch(
        requestRouteDistance({
          payload: {
            origin: this.breakdownCoordinates,
            destination: {
              ...(this.towLocation.aarId ? { id: this.towLocation.aarId } : {}),
              latitude: this.towLocation.lat,
              longitude: this.towLocation.lng,
            },
            checkTowDistance: true,
          },
        })
      )
    }
  }

  handleSelectDestination(destination: AARData) {
    const shop = aarAppendLocationMarker(destination)
    if (shop) {
      this.store$.dispatch(
        setAARAddress({
          payload: shop,
        })
      )
      this.handleNext()
    }
  }

  handleDetails(aarData: AARData) {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.PAGE_NAME_TOW_TO_DETAIL
    }, null, {
      location_name: aarData.name,
      location_code: aarData.id
    })
    this.aarDataSelected = aarData
    this.isModalVisible = true
  }

  handleCloseModal() {
    this.isModalVisible = false
  }

  handleFilter(type: AAR_FILTER_TYPES) {
    switch (type) {
      case AAR_FILTER_TYPES.OPEN:
        this.store$.dispatch(showOpenAARsOnly())
        this.store$.dispatch(requestMinimumAars())
        break
      case AAR_FILTER_TYPES.ALL:
        this.store$.dispatch(showAllAARs())
        break
    }
  }

  handleSorting({ type, order }: AARSorting) {
    switch (order) {
      case AAR_SORTING_ORDER.ASCENDING:
        this.store$.dispatch(setSortingType({ payload: { type, order: AAR_SORTING_ORDER.DESCENDING } }))
        this.adobeEventService.sendEvent({
          eventName: AdobeEventTypes.CTA,
          eventValue: `${events.towTo.DESTINATION_FACILITIES_SORTING} ${type} - Ascending`
        })
        break

      default:
        this.store$.dispatch(setSortingType({ payload: { type, order: AAR_SORTING_ORDER.ASCENDING } }))
        this.adobeEventService.sendEvent({
          eventName: AdobeEventTypes.CTA,
          eventValue: `${events.towTo.DESTINATION_FACILITIES_SORTING} ${type} - Descending`
        })
    }
  }

  selectPassengers(option: Option) {
    // More than two passengers should trigger the message
    const count = parseInt(option.value.replace(/\D/g, ''), 10)
    if (count >= 3) {
      this.store$.dispatch(
        openMessageDialog({
          payload: {
            type: MessageDialogTypes.CUSTOM,
            title: PASSENGER_TITLE_TRUCK_LIMIT(),
            content: PASSENGER_CONTENT_TRUCK_LIMIT(),
          },
        })
      )
    }

    const _action = `${events.towTo.PASSENGERS_COUNT_SELECT}: ${option.value || 1}`
    this.tagging.setClickEvent(_action, events.towTo.PASSENGERS_PAGE_TYPE)
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: _action,
    })

    if (count <= 2) {
      this.store$.dispatch(setPassengers({
        payload: option
      }))
      this.passengersNextStep()
    }
  }

  closePassengers() {
    this.editService.doEdit(StepTypes.TOWING, TowSections.TOW_MAP, false);
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.passengers.DISMISS
    })
  }

  passengersNextStep() {
    this.tagging.setClickEvent(
      events.towTo.PASSENGERS_NEXT_CLICK,
      events.towTo.PASSENGERS_PAGE_TYPE
    )

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.PASSENGERS_NEXT_CLICK
    })

    this.store$.dispatch(addPartialCallRequest())
    this.router.navigate([], {
      queryParams: {
        step: StepTypes.SUBMIT,
        section: SubmitSections.SUMMARY,
      },
    })
  }

  trackFacilitiesByFn(_: number, item: AARData): number {
    return item.id;
  }

  navigateBack() {
    this.isMapStep$.pipe(take(1)).subscribe((isMapStep) => {
      if (isMapStep) {
        this.showMap(false)
      } else {
        this.editService.navigateBack();
      }
    })
  }
}
