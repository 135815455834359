@if ((modalIndex$ | async) !== LocationModalState.LOCATION_DETAILS) {
  <app-header-redesign>
    <app-menu-button slot="start" />
    @if ((locationCard$ | async).display) {
      <ion-title>
        <app-logo />
      </ion-title>
    } @else {
      <app-title i18n>Breakdown Location</app-title>
    }
    <app-closable-header slot="end" />
  </app-header-redesign>
}

<ion-content>
  <div class="map-container">
    <ng-container *ngIf="(locationMap$ | async) as locationMap">
      <app-map-wrapper
        [centerMarker]="locationMap.centerMarker"
        [showGpsLocation]="true"
        [userLocation]="userCoords$ | async"
        [static]="locationMap.static"
        [displayZoomAction]="!locationMap.static"
        [center]="breakdownCoordinates$ | async"
        [displayLocateMeAction]="true"
        (mapDrag)="handleMapDrag($event)"
        [mapPadding]="mapPadding$"
        [zoomLevel]="zoomLevel$ | async"
        (userLocationClick)="useCurrentLocation({ isLocateUserFromMapClick: true })"
      >
        <div topSlot>
          <app-location-search #locationSearch
            *ngIf="!(isBreakdownLocationImprovementTest$ | async)"
            i18n-placeholder
            placeholder="Search"
            [selectedAddress]="selectedAddress"
            [location]="breakdownLocation"
            [gpsAvailable]="!(hasDeniedGpsAccess$ | async)"
            showUseCurrentLocation="true"
            (locationSelected)="handleLocationSelected($event)"
            (useCurrentLocation)="useCurrentLocation()"
            [lastSearchLocation]="lastSearchLocation$ | async"
            (inputClear)="inputCleared()"></app-location-search>
        </div>
        <div bottomSlot>
          <ng-container *ngIf="!(isBreakdownLocationImprovementTest$ | async) && (locationCard$ | async) as locationCard">
            <app-location-card
              *ngIf="locationCard.display || !selectedAddress; else adjustLocationCard"
              (onCurrentLocationClick)="useCurrentLocation()"
              (onHomeLocationClick)="useHomeLocation(locationCard.member)"
              [gpsDisabled]="hasDeniedGpsAccess$ | async"
              [userDefaultCoordsLoading]="locationCard.userDefaultCoordsLoading"
              [isHomeAddressVisible]="locationCard.displayHomeItem"
              [isLoading]="locationCard.loading"></app-location-card>
          </ng-container>
          <ng-template #adjustLocationCard>
            <app-adjust-location-card
              [loading]="isServicingClubConfigLoading$ | async"
              [address]="selectAdjustLocationAddress$ | async"
              [adjustRequired]="selectAdjustLocation$ | async"
              (onLocationConfirm)="handleLocationConfirm(true)"></app-adjust-location-card>
          </ng-template>
        </div>
      </app-map-wrapper>
  </ng-container>

    @if(isBreakdownLocationImprovementTest$ | async) {
      <drr-adjust-location-sheet
        [isOpen]="(modalIndex$ | async) === LocationModalState.ADJUST_LOCATION"
        [loading]="isLocationDetailsWait"
        [address]="selectAdjustLocationAddress$ | async"
        [adjustRequired]="selectAdjustLocation$ | async"
        [selectedAddress]="selectedAddress"
        (onLocationConfirm)="handleLocationConfirm()"
        (onInputClick)="handleEnterYourAddressClick()"
        (onClearAddress)="handleEnterYourAddressClick(true)"
        (onClose)="openLetsStartModal()"
      />

      <app-location-search-modal
        [isOpen]="(modalIndex$ | async) === LocationModalState.SEARCH_LOCATION"
        [selectedAddress]="selectedAddress"
        [gpsAvailable]="!(hasDeniedGpsAccess$ | async)"
        [location]="breakdownLocation"
        [lastSearchLocation]="lastSearchLocation$ | async"
        (locationSelected)="handleLocationSelected($event)"
        (useCurrentLocation)="useCurrentLocation()"
        (onClose)="navigateBack()"
        (onClear)="inputCleared()"
      />

      <location-details-sheet-variation
        [isOpen]="(modalIndex$ | async) === LocationModalState.LOCATION_DETAILS"
        [loading]="isServicingClubConfigLoading$ | async"
        [details]="details"
        (detailsChange)="handleOptionalDetailsChanged($event)"
        (adjustLocationClick)="handleAdjustLocationClick()"
        (nextClick)="handleNext()"
        (clearClick)="handleLocationSelected($event)"
        [address]="selectAdjustLocationAddress$ | async"
        (onClose)="openAdjustLocationModal()"
      />

      <app-lets-start-sheet-variation
        [isOpen]="!(modalIndex$ | async)"
        [isLoading]="isLoading"
        [canDismiss]="!!isValid && !addressCleared"
        (onDismiss)="!!isValid && openAdjustLocationModal()"
        [homeAddress]="(letsStartSheet$ | async).homeAddress"
        (homeAddressClick)="useHomeLocation(member)"
        [isRapUser]="isRapUser$ | async"
        [hasDeniedGpsAccess]="hasDeniedGpsAccess$ | async"
        (enterYourAddressClick)="handleEnterYourAddressClick()"
        (findMyLocationClick)="useCurrentLocation({ isFindMyLocationClick: true })"
      />

    } @else {
      <app-lets-start-sheet
        [isLoading]="isLoading"
        [isOpen]="(showFullMap$ | async) && canPromptLocationPermissions() && !(isBreakdownLocationImprovementTest$ | async)"
        [isRapUser]="isRapUser$ | async"
        [hasDeniedGpsAccess]="hasDeniedGpsAccess$ | async"
        (enterYourAddressClick)="handleEnterYourAddressClick()"
        (findMyLocationClick)="useCurrentLocation({ isFindMyLocationClick: true })"
        (onDismiss)="turnOffLetsStartSheet()"
      ></app-lets-start-sheet>

      <location-details-sheet
        [(isOpen)]="isLocationDetailsDisplay"
        [details]="details"
        (detailsChange)="handleOptionalDetailsChanged($event)"
        (adjustLocationClick)="handleAdjustLocationClick()"
        (nextClick)="handleNext()"
        (clearClick)="handleLocationSelected($event)"
        [address]="selectAdjustLocationAddress$ | async"
      ></location-details-sheet>
    }

  </div>
</ion-content>
