import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core'
import { ModalComponent } from '@aaa-mobile/shared-components'
import { getBrowserImageDRR, getBrowserImageRAP } from '../../../location/location.utils'
import { PERMISSION_STATE } from '../../google-geocode/types';
import { GoogleGeocodeService } from '../../google-geocode/google-geocode.service'
import { isIOS } from '../../../../shared/utils/browser-detect';

@Component({
  selector: 'app-lets-start-sheet-variation',
  templateUrl: './lets-start-sheet-variation.component.html',
  styleUrls: ['./lets-start-sheet-variation.component.scss'],
})
export class LetsStartSheetVariationComponent implements OnChanges, OnInit {
  @ViewChild(ModalComponent, { static: true }) modalElem!: ModalComponent

  @Input() isRapUser = false
  @Input() isLoading = false
  @Input() hasDeniedGpsAccess = false
  @Input() isOpen = true;
  @Input() homeAddress: string | undefined = undefined;
  @Input() canDismiss = false;

  @Output() enterYourAddressClick = new EventEmitter()
  @Output() findMyLocationClick = new EventEmitter()
  @Output() homeAddressClick = new EventEmitter()
  @Output() onDismiss = new EventEmitter()
  @Output() gpsAccessDenied = new EventEmitter<boolean>()

  public browserImage: ReturnType<
    typeof getBrowserImageDRR | typeof getBrowserImageRAP
  >
  public currentBreakpoint: number
  showFindMyLocation = true
  showEnterMyAddress = false
  permissionState: PERMISSION_STATE;

  constructor(private locationService: GoogleGeocodeService) {
  }

  ngOnInit() {
    this.browserImage = this.getBrowserImage()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.locationService.getGeolocationPermission().then((state: PERMISSION_STATE) => {
      this.permissionState = state
    })
    if (changes.isRapUser) {
      this.browserImage = this.getBrowserImage()
    }
    if (changes.permissionState) {
      this.showFindMyLocation = this.permissionState !== PERMISSION_STATE.DENIED
      this.showEnterMyAddress = this.permissionState === PERMISSION_STATE.DENIED
    }
    if (changes.hasDeniedGpsAccess) {
      this.showFindMyLocation = !this.hasDeniedGpsAccess
      this.showEnterMyAddress = this.hasDeniedGpsAccess
    }
  }

  handleFindMyLocation() {
    if (isIOS()) {
      navigator.geolocation.getCurrentPosition(() => {
        this.findMyLocationClick.emit();
      },
      (error) => {
        if (error.code === 1) { // PERMISSION_DENIED
          this.gpsAccessDenied.emit(true);
          this.showFindMyLocation = false;
          this.showEnterMyAddress = true;
        }
        this.findMyLocationClick.emit();
      }
      );
    } else {
      this.findMyLocationClick.emit();
    }
  }

  handleEnterYourAddress() {
    this.enterYourAddressClick.emit()
    this.isOpen = false;
  }

  handleHomeAddressClick() {
    this.homeAddressClick.emit()
    this.isOpen = false;
  }

  setCurrentBreakpoint(event) {
    this.currentBreakpoint = event.detail.breakpoint
  }

  getBrowserImage(): ReturnType<
    typeof getBrowserImageDRR | typeof getBrowserImageRAP
  > {
    return !this.isRapUser ? getBrowserImageDRR() : getBrowserImageRAP()
  }

  handleWillDismiss(
    event: CustomEvent<HTMLIonModalElementEventMap['ionModalWillDismiss']>
  ) {
    if (['backdrop', 'gesture'].includes(event.detail.role)) {
      this.onDismiss.emit()
    }
  }

  isGpsStatePrompt() {
    return [PERMISSION_STATE.UNKNOWN, PERMISSION_STATE.PROMPT].includes(this.permissionState)
  }
}
