import { TowLocationPromoComponent } from './tow-location-promo/tow-location-promo.component'
import {
  TowLocationListSkeletonComponent
} from './tow-location-list-item/tow-location-skeleton-item/tow-location-skeleton-item.component'
import {
  BreakdownLocationStepComponent
} from '../breakdown-location/breakdown-location-step/breakdown-location-step.component';
import { AdjustLocationCardComponent } from '../breakdown-location/adjust-location-card/adjust-location-card.component'
import { TowLocationStepComponent } from '../tow-location/tow-location-step/tow-location-step.component';
import { ShopPreferencesComponent } from './shop-preferences/shop-preferences.component'
import { PassengersComponent } from './passengers/passengers.component'
import { DrrLocationHighwayExit } from '../breakdown-location/components/location-highway-exit/location-highway-exit.component';
import { LocationDetailsSheetComponent } from '../breakdown-location/components/location-details-sheet/location-details-sheet.component';
import { DrrAdjustLocationSheet } from '../breakdown-location/adjust-location-card/variation/adjust-location-sheet.component';
import {
  LocationDetailsSheetVariationComponent
} from '../breakdown-location/components/location-details-sheet/variation/location-details-sheet-variation.component'

export function locationComponents() {
  return [
    BreakdownLocationStepComponent,
    TowLocationStepComponent,
    TowLocationPromoComponent,
    TowLocationListSkeletonComponent,
    AdjustLocationCardComponent,
    ShopPreferencesComponent,
    PassengersComponent,
    LocationDetailsSheetComponent,
    LocationDetailsSheetVariationComponent,
  ]
}

export function locationModules() {
  return [
    DrrLocationHighwayExit,
    DrrAdjustLocationSheet
  ]
}
